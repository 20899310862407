import React from 'react'
import { useSelector } from 'react-redux';
import styled from '@emotion/styled'
import CardList from './CardList';
import SearchCard from './SearchCards';
import { filterSearchResults } from '../../helper-functions/filterSearchResults';

const SearchLanding = styled.div`
    margin: 6rem 0;
    
    h3 {
        font-family: ${props => props.theme.fonts.title};
        font-size: 3rem;
        text-align: center;

        @media(max-width: 766px) {
            font-size: 2rem;
        }
    }
`

const CardSectionOne = ({ posts, basePath }) => {
    let filteredPosts = [];
    const isExplorePanel = false;

    const searchValue = useSelector(state => state.valueStorage[0].searchValue).toLowerCase();

    filteredPosts = filterSearchResults({ posts, filteredPosts, isExplorePanel }, searchValue)

    const searchResults = (searchResults) => {
        if (searchResults.length > 0) {
            return <CardList>
                {filteredPosts.map(({ node: post }) => {
                    return <SearchCard key={post.id} {...post} basePath={basePath} />
                })
                }
            </CardList>
        }

        return <SearchLanding>
            <h3>No results for {searchValue}, search again</h3>
        </SearchLanding>
    }

    return (
        searchValue ?
            searchResults(filteredPosts)
        :
            <SearchLanding>
                <h3>Go ahead, make a search</h3>
            </SearchLanding>
    )
}

export default CardSectionOne