export const shuffle = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export const setURL = (urlParamProperty, urlParamValue) => {
    let urlLocation = typeof window !== "undefined" ? window.location : "";
    let urlHistory = typeof window !== "undefined" ? window.history : "";
    let target = urlLocation.pathname + "?" + `${urlParamProperty}=${urlParamValue}`;

    if (urlHistory === "") {
        return;
    }

    urlHistory.replaceState({ path: urlParamProperty }, "", target);
}