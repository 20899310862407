import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { updateSearchValue } from '../../actions/valueChanges';
import * as constants from "../../constants";
import { setURL } from "../../helper-functions/helperFunctions";

const SearchBarPageContainer = styled.div`
    flex-grow: 1;

    @media(max-width: 600px) {
        align-self: center;
    }
`

const SearchForm = styled.form`
    display: inline-block;
    margin-bottom: -.45rem;
    position: relative;
    width: 100%;

    svg.fa-search {
        @media(max-width: 600px) {
            font-size: 1.5rem;
        }
    }

    input[type='text'] {
        border-bottom: solid 1px black;
        font-family: ${props => props.theme.fonts.title};
        font-size: 2.25rem;
        padding: .125rem 0;
        transition: width 0.3s ease-in;
        width: 90%;

        &::placeholder {
            color: transparent;
        }

        @media(max-width: 600px) {
            font-size: 1.5rem;
        }
    }

    button {
        padding-left: 0;

        svg {
            display: inline-block;
        }

        @media(max-width: 600px) {
            padding-right: 0;
        }
    }
`

const SearchBar = () => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");
    const initialSearchValue = useSelector(state => state.valueStorage[0].searchValue);

    useEffect(() => {
        setSearchValue(initialSearchValue);
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();

        if (searchValue === "") {
            return;
        }

        dispatch(updateSearchValue(searchValue));
        setURL(constants.SEARCH_PARAM, searchValue);
    }

    const handleInput = (e) => {
        const value = e.target.value;

        setSearchValue(value);
    }

    return (
        <SearchBarPageContainer>
            <SearchForm action="">
                <input type="text" id="Search" placeholder="Search" onChange={handleInput} value={searchValue} />
                <label htmlFor="Search">
                    <span className="visually-hidden">Search</span>
                </label>
                <button
                    onClick={handleSubmit}>
                    <span className="visually-hidden">Submit</span>
                    <FontAwesomeIcon icon={faSearch} size="2x" color="#000" />
                </button>
            </SearchForm>
        </SearchBarPageContainer>
    )
}

export default SearchBar;