import React from 'react'
import styled from '@emotion/styled'

const Title = styled.h1`
    margin: 0 auto 1.875rem;
    max-width: ${props => props.theme.sizes.maxWidthCentered};
    font-size: 2.25rem;
    font-family: ${props => props.theme.fonts.title};

    &.faq-title {
        max-width: unset;
        margin-top: 2.5rem;
    }

    @media (max-width: 767px) {
        text-align: center;
    }
`

const PostTitle = ({ title, className }) => {

    return (
        <Title className={className} >
            {title}
        </Title>
    )
}

export default PostTitle
