export const filterSearchResults = (postsInfo, searchValue) => {
    const searchRegex = new RegExp(`\\b${searchValue}\\b`, "gi");
    const { posts, filteredPosts, isExplorePanel } = postsInfo;

    if (isExplorePanel) {
        for (let i = 0, len = posts.length; i < len; i++) {

            if (searchValue === "" || searchValue === "the" || searchValue === "and" || searchValue === "a") {
                filteredPosts.push(posts[i]);

                continue;
            }

            if (
                !determineSearchValueMatch(posts[i].node, searchRegex)
            ) {
                filteredPosts.push(posts[i]);
            }
        }
    } else {
        for (let i = 0, len = posts.length; i < len; i++) {

            if (searchValue === "" || searchValue === "the" || searchValue === "and" || searchValue === "a") {
                continue;
            }
    
            if (
                determineSearchValueMatch(posts[i].node, searchRegex)
                ||
                filterForTags(posts[i].node.tags, searchValue)
            ) {
                filteredPosts.push(posts[i]);
            }
        }
    }

    return filteredPosts;
}

const determineSearchValueMatch = (post, searchRegex) => {
    const authorCriteria = post.authors[0].title.toLowerCase().match(searchRegex);
    const descriptionCriteria = post.metaDescription.childMarkdownRemark.excerpt.toLowerCase().match(searchRegex);
    const titleCriteria = post.title.toLowerCase().match(searchRegex);

    if (authorCriteria || descriptionCriteria || titleCriteria) {
        return true;
    }
}

const filterForTags = (tags, searchValue) => {
    if (!tags) {
        return;
    }

    let tagMatch = false;

    for (let i = 0, len = tags.length; i < len; i++) {
        if (tags[i].title.toLowerCase().includes(searchValue)) {
            tagMatch = true;
            break;
        }
    }

    return tagMatch;
}