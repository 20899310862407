import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PostTitle from '../components/Post/PostTitle'
import SEO from '../components/SEO'
import SearchExplorePanel from '../components/Search/SearchExplorePanel';
import SearchCardSection from "../components/Cards/SearchCardSection";
import SearchBar from "../components/Search/SearchBarPage";
import { updateSearchValue } from '../actions/valueChanges';
import * as constants from "../constants";
import { setURL } from "../helper-functions/helperFunctions";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  > ul {
    margin: 3rem 0;
  }

  @media (max-width: 766px) {
    flex-wrap: wrap;

    > ul {
      justify-content: center;
      margin: 4rem 0;
    }

    aside {
      overflow: visible;
    }
  }
`;

const SearchFormContainer = styled.div`
  display: flex;
  align-items: flex-end;
  
  h1 {
    margin: 2.5rem 1rem 0 0;
  }

  @media(max-width: 600px) {
    align-items: flex-start;
    flex-direction: column;

    h1 {
      margin-bottom: 1rem;
    }
  }
`

const Search = ({ data }) => {
  const dispatch = useDispatch();
  const posts = data.allContentfulPost.edges
  const pageTitle = "Results:";
  const faqClass = "faq-title";
  const explorePanelTitle = "Explore Something Else";
  const searchValue = useSelector(state => state.valueStorage[0].searchValue);
  let urlLocation = typeof window !== "undefined" ? window.location : "";

  useEffect(() => {
  }, [])

  const handleParam = () => {
    const params = new URLSearchParams(urlLocation.search);
    const searchParam = params.get(constants.SEARCH_PARAM);

    if (!searchParam) {
      setURL(constants.SEARCH_PARAM, searchValue)

      return;
    }

    dispatch(updateSearchValue(searchParam));
  }

  handleParam();

  return (
      <Layout>
          <SEO title="Search" description="Search page for Eklectish content" />
          <Container>
              <SearchFormContainer>
                <PostTitle title={pageTitle} className={faqClass} />
                <SearchBar />
              </SearchFormContainer>
              <ContentContainer>
                  <SearchCardSection posts={posts} basePath={""} />
                  <SearchExplorePanel posts={posts} basePath={""} currentPostId={0} title={explorePanelTitle} />
              </ContentContainer>
          </Container>
      </Layout>
  )
}

export default Search


export const query = graphql`
  query {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          title
          id
          slug
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            ogimg: resize(width: 1800) {
              src
            }
          }
          authors {
            title
          }
          metaDescription {
            childMarkdownRemark {
              excerpt(pruneLength: 100)
            }
          }
          tags {
            title
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
  }
`