import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Post = styled.li`
  position: relative;
  margin: 0 0 2rem 0;
  width: 100%;
  transition: ${props => props.theme.effects.transition};

  &:last-of-type {
      margin-bottom: 0;
  }

  &::after {
    ${props => props.theme.outlines.tileSectionBorderBottom}
    bottom: -1rem;
  }

  @media(max-width: 766px) {
    margin-bottom: 4rem;

    &::after {
      bottom: -2rem;
    }
  }

  @media(min-width: 550px) and (max-width: 766px) {
    width: 80%;
  }

  a {
      color: ${props => props.theme.colors.text};
    display: flex;
    height: 100%;
    text-decoration: none;
    width: 100%;

    .gatsby-image-wrapper {
        display: block;
        flex-shrink: 0;
        height: 125px;
        width: 185px;

        @media (max-width: 766px) {
          margin-bottom: 1rem;
          height: 350px;
          width: 100%;
        }

        @media(max-width: 550px) {
          height: 200px;
        }
    }
    
    &:hover {
      h4 {
        color: ${props => props.theme.colors.highlight};
      }
    }

    @media(max-width: 766px) {
      align-items: center;
      flex-direction: column;
    }
  }
`

const StyledImg = styled(Img)`

`

const Title = styled.h2`
  font-size: 2.25rem;
  margin-bottom: 1rem;
  font-weight: 700;
  font-family: ${props => props.theme.fonts.title};

  @media(max-width: 766px) {
    font-size: 1.75rem;
    text-align: center;
  }

  @media (max-width: 450px) {
    font-size: 1.5rem;
  }
`

const Author = styled.h3`
  color: ${props => props.theme.colors.secondary};
  font-family: ${props => props.theme.fonts.copy};
  font-weight: 500;
  margin-bottom: 0.5rem;

  @media(max-width: 766px) {
    text-align: center;
  }
`

const Excerpt = styled.p`
  line-height: 1.6;
  font-family: ${props => props.theme.fonts.copy};
  font-weight: 400;

  @media (max-width: 766px) {
    text-align: center;
  }
`

const PostDetailsContainer = styled.article`
  padding: 0 0.5rem 0.5rem 1.5rem;
  height: auto;

  @media(max-width: 766px) {
    padding: 0;
  }
`

const SearchCard = ({ slug, heroImage, title, authors, body, metaDescription, ...props }) => {
    const authorName = authors ? authors[0].title : ''
    const metaDescriptionText = metaDescription.childMarkdownRemark.excerpt;
    return (
        <>
            {heroImage && body && (
                <Post featured={props.featured}>
                    <Link to={`${props.basePath}/${slug}/`}>
                        <StyledImg fluid={heroImage.fluid} backgroundColor={'#eeeeee'} />
                        <PostDetailsContainer>
                            <Title>{title}</Title>
                            <Author>{authorName ? authorName : 'Test Author'}</Author>
                            <Excerpt>
                                {metaDescriptionText}
                            </Excerpt>
                        </PostDetailsContainer>
                    </Link>
                </Post>
            )}
        </>
    )
}

export default SearchCard
