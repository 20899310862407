import React from 'react'
import { useSelector } from 'react-redux';
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { shuffle } from '../../helper-functions/helperFunctions';
import { filterSearchResults } from '../../helper-functions/filterSearchResults';

const Wrapper = styled.aside`
    margin: 0 0 0 0;
    overflow: hidden;
    width: 100%;

    @media (max-width: 400px) {
        padding: 0 .5rem 0;
    }
`

const Container = styled.div`
`

const ExploreLinksTitle = styled.h3`
    margin-bottom: 1.75rem;
    font-family: ${props => props.theme.fonts.title};
    font-weight: 400;
    display: inline-block;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        border-bottom: 0.188rem solid ${props => props.theme.colors.secondary};
        right: 0;
        top: 0;
        left: 0;
        bottom: -0.5rem;
    }

    font-size: 2.25rem;
`

const List = styled.ul`
    display: flex;
    justify-content: space-between;
    position: unset;
    max-width: unset;
    width: 100%;
    flex-direction: row;

    @media(max-width: 1050px) {
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 0.625rem;
    }
`

const Item = styled.li`
    margin-bottom: 1.5rem;
    position: relative;
    
    &:not(:last-of-type) {
        margin-right: 1.875rem;
    }

    &:after {
        content: "";
        position: absolute;
        border-bottom: ${props => props.theme.outlines.borders};
        left: 0;
        right: 0;
        bottom: -0.5rem;
        top: auto;
    }
`;

const ItemLink = styled(Link)`
    color: black;
    background: transparent;
    border-radius: 0.125rem;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    min-height: 400px;
    padding: 0;
    text-decoration: none;
    transition: ${props => props.theme.effects.transition};
    width: 350px;

    img {
        height: 200px;
        object-fit: cover;
        width: 100%;
    }

    @media(max-width: 1150px) {
        width: 300px;
    }

    @media(max-width: 400px) {
        width: 250px;
    }
`

const ExplorePostDescriptionContainer = styled.div`
    padding-left: 0.5rem;
    height: 150px;
    width: 100%;
    padding-top: 0.625rem;

    @media(max-width: 766px) {
        padding-left: 0;
    }
`

const ExplorePostTitle = styled.h3`
    font-weight: 500;
    font-family: ${props => props.theme.fonts.title};
    font-size: 1.75rem;
`

const ExplorePostAuthor = styled.p`
    font-weight: 400;
    font-size: 1rem;
    margin-top: 0.5rem;
    font-family: ${props => props.theme.fonts.copy};
    color: ${props => props.theme.colors.secondary};
`

const SearchExplorePanel = ({ basePath, posts, currentPostId, title, }) => {
    const searchValue = useSelector(state => state.valueStorage[0].searchValue).toLowerCase();
    let filteredPosts = [];
    const isExplorePanel = true;

    filteredPosts = filterSearchResults({ posts, filteredPosts, isExplorePanel }, searchValue)

    const randomizedPosts =
        shuffle(filteredPosts)
            .filter(post => {
                if (post.node.id != currentPostId) {
                    return post
                }
            })
            .slice(0, 3)
        ;

    return (
        <Wrapper>
            <Container>
                <ExploreLinksTitle>{title}</ExploreLinksTitle>
                <List>
                    {
                        randomizedPosts.map(post => {
                            return (
                                <Item key={post.node.id}>
                                    <ItemLink
                                        to={`${basePath}/${post.node.slug}`}
                                    >
                                        <img src={post.node.heroImage.fluid.src} alt="" />
                                        <ExplorePostDescriptionContainer>
                                            <ExplorePostTitle>
                                                {post.node.title}
                                            </ExplorePostTitle>
                                            <ExplorePostAuthor>
                                                {post.node.authors ? post.node.authors[0].title : 'Test Author'}
                                            </ExplorePostAuthor>
                                        </ExplorePostDescriptionContainer>
                                    </ItemLink>
                                </Item>
                            )
                        })
                    }
                </List>
            </Container>
        </Wrapper>
    )
}

export default SearchExplorePanel
